import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';

import CustomNoRowsOverlay from './noRowsOverlay';
import CustomLoadingOverlay from './loadingOverlay';
import CustomPagination from './customPagination';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../Notification/Snackbar';
// import { translateThis } from '../../helpers/language.helper';
import AppPopOverMenu from '../AppPopOverMenu/index';
import ConfirmDialog from '../dialogs/Confirm.dialog';


// Create the data grid options object with columns and rowData properties set to our own values
const AppTable = (props) => {
    const {
        columns = [], // columns without actions id column
        dataURL = '',
        mobileRowRender = () => <></>,
        isRefreshData = false,
        onRefreshSuccess = () => null, // Callback to reset the parent state so that next refresh will happen
        actionColumnSettings = {},
        isDeleteActionEnabled = true,
        isEditActionEnabled = true,
        isViewActionEnabled = true,
        extraActions = () => null
    } = props;

    const initialData = {
        limit: 25,
        page: 1,
        result: [],
        totalCount: 0,
        totalPages: 0
    }

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState(initialData);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [forceRefresh, setForceRefresh] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr();
        if (isRefreshData) {
            onRefreshSuccess();
        }

        return () => {
            setDataArr(initialData)
        };
        // eslint-disable-next-line
    }, [forceRefresh, isRefreshData]);


    // get resource from api
    const getDataArr = (limit = 25, page = 1) => {
        try {
            if (page > 0 && dataURL) {
                setIsLoading(true);
                const apiCaller = new ApiCaller(dataURL);
                apiCaller.getList({ limit, page })
                    .then(({ data }) => {
                        if (data?.totalCount && data?.result) {
                            setDataArr(data)
                        }
                    })
                    .catch(() => showSnackbar().failure('Something went wrong'))
                    .finally(() => {
                        setIsLoading(false);
                    });
            }

        } catch (error) {
            console.log(error)

        }
    }



    /**
   * Property delete
   */
    const deleteData = () => {
        if (selectedId) {
            const apiUrl = dataURL.split('?')[0]; // omit query params
            const apiCaller = new ApiCaller(apiUrl);
            apiCaller.customPatchData(selectedId, { isDeleted: true })
                .then((d) => d.success ? showSnackbar().success('Resource deleted successfully') :
                    showSnackbar().failure("Something went wrong!."))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    setForceRefresh('delete');
                })
        }

    }

    /**
     * On delete button click
     * @param {Object} row 
     */
    const onDeleteClick = (row) => {
        setOpenDeleteDialog(true);
        setSelectedId(row?.id);
    }


    /**
     * 
     * @returns JSX Element
     */
    function CustomToolbar() {
        return (
            <GridToolbarContainer className='app-grid-tool-bar'>
                <GridToolbarExport
                    printOptions={{
                        disableToolbarButton: true
                    }}
                />
                <GridToolbarQuickFilter debounceMs={1000} />
            </GridToolbarContainer>
        );
    }

    /**
     * Renders the column
     */
    const renderColumns = () => {
        const actionColumn = {
            field: 'actions',
            headerName: 'Actions',
            headerAlign: 'center',
            align: 'center',
            flex: 2,
            width: 135,
            minWidth: 135,
            maxWidth: 135,
            sortable: false,
            ...actionColumnSettings,
            renderCell: ({ row }) => (
                <>
                    {isViewActionEnabled ? <span><IconButton onClick={() => navigate(`view/${row.id}`)} children={<Visibility />} /></span> : null}
                    {isEditActionEnabled ? <span><IconButton onClick={() => navigate(`edit/${row.id}`)} children={<Edit />} /></span> : null}
                    {isDeleteActionEnabled ? <span> <IconButton onClick={() => onDeleteClick(row)} children={<Delete />} /> </span> : null}
                    {typeof extraActions === 'function' ? <AppPopOverMenu options={extraActions(row)} /> : null}
                </>
            )
        }
        columns.push(actionColumn);
        const uniqueColumns = columns.filter((obj, index, self) => self.findIndex((o) => o.field === obj.field) === index);
        return uniqueColumns;

    }

    const onFilterChange = (e) => {
        console.log(e)
    }
    const rowHeight = 75;

    return (
        <>
            <Box className="PC-Table" sx={{ width: '100%' }}>
                <DataGrid
                key={dataArr.length}
                    density='comfortable'
                    rows={dataArr.result || []}
                    autoHeight
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    loading={isLoading}
                    columns={renderColumns()}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    rowHeight={rowHeight}
                    
                    sx={{
                        '& .MuiDataGrid-row': {
                            // paddingBottom: '10px',
                            // paddingTop: '6px',
                            // paddingBottom: '6px',
                            borderTop: '5px solid #f1f1f1',
                            borderBottom: '5px solid #f1f1f1',
                        },
                        '& .MuiDataGrid-row:last-child': {
                            marginBottom: '0px',
                            borderBottom: 'none'
                        }
                    }}
                    // sx={{
                    //     // '& .MuiDataGrid-row': {
                    //     //   paddingTop: '6px',    // Padding inside each row (top)
                    //     //   paddingBottom: '6px', // Padding inside each row (bottom)
                    //     // },
                    //     // '& .MuiDataGrid-virtualScrollerContent': {
                    //     //   paddingTop: '6px',    // Match padding on the virtual scroller content
                    //     //   paddingBottom: '6px', // Match padding on the virtual scroller content
                    //     // },
                    //     // '& .MuiDataGrid-virtualScrollerRenderZone': {
                    //     //   gap: '10px',    // Match padding on the virtual scroller content
                    //     // }

                    //   }}
                    pageSizeOptions={[0, 10, 15, 25]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    pagination
                    slots={{
                        toolbar: CustomToolbar,
                        pagination: () => CustomPagination({ ...dataArr, onPageChange: getDataArr }),
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingOverlay,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>
            <Box className="mobile-Table">
                {/* <ul>
                    {dataArr.result.map((row, rowIndex) => (
                        <li key={rowIndex}>
                            {renderColumns().map((column, columnIndex) => {
                                if (!row) return null;
                                return (

                                    <div key={columnIndex}>
                                        {column.renderCell ? column.renderCell({ row }) : column.valueFormatter ? column.valueFormatter({ value: row[column.field] }) : <span>{row[column.field]}</span>}
                                    </div>
                                )
                            })}
                        </li>
                    ))}
                </ul> */}

                <div className='mob-table-wrp f-wrp'>
                    <div className='mob-table-search'>
                        <input className='mob-table-input' placeholder={'Search'} onChange={(e) => null} />
                    </div>
                    {dataArr?.result.length > 0 ? <>
                        <ul>
                            {
                                dataArr?.result.map((row, i) => <li key={i}>{mobileRowRender(row)}</li>)
                            }
                        </ul>

                    </> : isLoading ? <span>Loading...</span> : <span>No results found</span>}
                </div>




            </Box>
            {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Confirm delete`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this resource?\nThis action is permanent and cannot be undone.`}
                okayButtonText={'Delete'}
                onConfirm={deleteData}
            />}
        </>
    );
}

AppTable.propTypes = {
    isRefreshData: PropTypes.any,
    onRefreshSuccess: PropTypes.any,
    mobileRowRender: PropTypes.any,
    dataURL: PropTypes.string,
    columns: PropTypes.array,
    actionColumnSettings: PropTypes.object,
    isDeleteActionEnabled: PropTypes.bool,
    isEditActionEnabled: PropTypes.bool,
    isViewActionEnabled: PropTypes.bool,
    extraActions: PropTypes.any
}

export default AppTable;