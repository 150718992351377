import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import RequestPage from '../pages/RequestPage';
import ListingPage from '../pages/property/ListingPage';
import LoginPage from '../pages/LoginPage';
import WelcomePage from '../pages/WelcomePage';
import Page404 from '../pages/Page404';
import ProductsPage from '../pages/ProductsPage';
// import DashboardAppPage from '../pages/DashboardAppPage';
import Profile from '../pages/Profile';
import EditListItem from '../pages/property/EditListItem';
import AddListItem from '../pages/property/AddListItem';
import ViewProperty from '../pages/property/ViewProperty';
import LogoutPage from '../pages/LogoutPage';
import ContactUsListingPage from '../pages/ContactUsQueries';
import BlogSection from '../pages/Blog';
import UserListLayout from '../pages/users/userListLayout';
import AddEditViewUser from '../pages/users/addEditViewUser';
import { isLoggedIn } from '../helpers/auth.helper';
import AddEditTrustedPartners from '../pages/trustedPartners/addEditTrustedPartners';

// ----------------------------------------------------------------------

export default function Router() {
  const isUserLoggedIn = isLoggedIn();
  const routes = [

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'logout',
      element: <LogoutPage />,
    },
    {
      path: 'admin',
      element: <WelcomePage />,
    },
    {
      path: 'contact-us-queries',
      element: <ContactUsListingPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const privateRoutes = isUserLoggedIn ? [
    {
      path: '/dashboard',
      // element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        // { path: 'app', element: <DashboardAppPage /> },
        { path: 'app', element: <ListingPage /> },
        { path: 'request', element: <RequestPage /> },
        { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
        { path: 'profile', element: <Profile /> },
        { path: 'edit/:id', element: <EditListItem /> },
        { path: 'property/view/:id', element: <ViewProperty /> },
        { path: 'add-property', element: <AddListItem /> },
      ],
    },
    {
      path: '/users',
      children: [
        { element: <UserListLayout activeTab={'0'} />, index: true },
        { path: 'member', element: <UserListLayout activeTab={'0'} />, index: true },
        { path: 'volunteer', element: <UserListLayout activeTab={'1'} />, index: true },
        { path: 'staff', element: <UserListLayout activeTab={'2'} />, index: true },
        { path: 'add', element: <AddEditViewUser /> },
        { path: ':type/:id', element: <AddEditViewUser /> },
        { path: ':id', element: <AddEditViewUser /> },
      ],
    },
    {
      path: '/blog',
      children: [
        { element: <BlogSection />, index: true },
        { path: ':page', element: <BlogSection />, index: true }, // true for news/add news/view news/edit
        { path: ':page/:id', element: <BlogSection />, index: true }, // true for news/add news/view news/edit
      ],
    },
    {
      path: '/trusted-partners',
      children: [
        { element: <AddEditTrustedPartners />, index: true },
      ],

    },
  ] : [];

  return useRoutes(routes.concat(privateRoutes));
}
