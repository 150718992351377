import React, { useEffect, useState } from 'react';
import { Button, Container, Typography } from '@mui/material';

import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/Notification/Snackbar';
import FileUploader from '../../components/AwsS3ImageUploader/file.uploader';
import AppLayout from '../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';
import S3ImageViewerWithFallback from '../../components/awsS3ImageViewer/ImgViewerWithFallback';
import deleteIcon from '../../assets/images/Delete.svg';
import './index.scss';


const AddEditTrustedPartners = () => {

    const apiCaller = new ApiCaller('settings');
    const [trustedPartnersImages, setTrustedPartnersImages] = useState([]);

    useEffect(() => {
        getData();
        return () => {
            setTrustedPartnersImages({});
        };
        // eslint-disable-next-line
    }, []);

    const getData = () => {
        apiCaller.getData('our-trusted-partners')
            .then((data) => {
                if (data?.optionValue) {
                    setTrustedPartnersImages(data.optionValue);
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
    }

    const handleSubmit = () => {
        if (trustedPartnersImages.length > 0) {
            const postData = {
                partners: trustedPartnersImages,
            }
            const action = saveData;

            action(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success('List Updated');
                } else {
                    showSnackbar().failure('Something went wrong');
                }
            })

        } else {
            showSnackbar().warning('Please select an image');
        }
    }

    const saveData = (postData) => apiCaller.customPostData('add-trusted-partners', postData);

    const deletePartnerImg = (fileKey) => setTrustedPartnersImages((prv) => prv.filter((img) => img !== fileKey))

    const pageName = "Trusted partners";

    return (
        <AppLayout
            pageName={pageName}
        >
            <div className="main-content-wrapper trustedPartner-wrp f-wrp">
                <div className='panel-main-header f-wrp'>
                    <h1>{'Trusted partners'}</h1>
                    <HeaderRightUserInfo />
                </div>
                <div className="main-container-wrp">
                    <Container>
                        <div className='list-main-wrp addEditNews trustedPartner f-wrp' style={{ paddingBottom: '0', paddingTop: '40px' }}>
                            <div className='title-head f-wrp'>
                                <div className='title-sec'>
                                    <Typography variant="h4" gutterBottom>Trusted partners</Typography>
                                </div>
                            </div>
                            <div className='partners-image-wrp f-wrp'>
                                <div className='image-uploader'>
                                    <FileUploader
                                        key={'trusted-partners-images'}
                                        uploaderKey={'trusted-partners-images'}
                                        uploadCaption=''
                                        onUploadEnd={(img) => setTrustedPartnersImages((prv) => [...prv, img.fileKey])}
                                    />
                                </div>

                                {(trustedPartnersImages.length > 0) ? <div className='image-preview'>
                                    {trustedPartnersImages.map((element, key) => (
                                        <span className='each-img-box' key={key}>
                                            <button type='button' className='delete-btn' onClick={() => deletePartnerImg(element)}><img src={deleteIcon} alt="edit" /></button>
                                            <S3ImageViewerWithFallback key={`file-${element}`} fileKey={element} />
                                        </span>
                                    ))}

                                </div> : null}

                                <div className='btn-wrp'>
                                    <Button onClick={handleSubmit} fullWidth variant="contained" className='blu-btn' startIcon={<></>}>
                                        Publish
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </AppLayout >
    )
}

export default AddEditTrustedPartners;