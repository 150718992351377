import * as React from 'react';
import { useParams } from 'react-router-dom';

import '../index.scss';
import './index.scss';

import BlogList from './list';
import AddEditBlog from './addEdit';
import AppLayout from '../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';



export default function BlogSection() {
    const pageName = "Blog";
    const { page = 'list' } = useParams()

    const getSelectedContent = () => {
        const selectedItem = page;
        let contentSelected = <BlogList />
        switch (selectedItem) {
            case 'list':
                contentSelected = <BlogList />;
                break;
            case 'view':
                contentSelected = <AddEditBlog />;
                break;
            case 'add':
                contentSelected = <AddEditBlog />;
                break;
            case 'edit':
                contentSelected = <AddEditBlog />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        return contentSelected;
    }



    return (
        <>
            <AppLayout
                pageName={pageName}
            >
                <div className="main-content-wrapper news-main-wrapper f-wrp">
                    <div className='panel-main-header f-wrp'>
                        <h1>{'Blogs'}</h1>
                        <HeaderRightUserInfo />
                    </div>
                    <div className="main-container-wrp">
                        {getSelectedContent()}
                    </div>
                </div>
            </AppLayout >
        </>
    );
}