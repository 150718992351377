import React, { } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container, Stack, Typography } from '@mui/material';
import './index.scss';
import TabLayout from '../../components/tabs';
import Iconify from '../../components/iconify';
import UserList from './list';
import { USER_ROLES } from '../../constant';
import innerBanner from '../../assets/images/Imgbanner.png';
import AppLayout from '../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';




const UserListLayout = ({ activeTab = '0' }) => {
    const navigate = useNavigate();

    const tabNames = [
        // { name: <b>{'Admin'}</b>, icon: <SVGIcons.MemberIcon /> },
        { name: <b>{'Admin'}</b> },
        { name: <b>{'External Agents'}</b> },
        { name: <b>{'Clients'}</b> }
    ];
    const tabContents = [
        // <span key={'to_members'}><UserList type={USER_ROLES.admin} /></span>,
        <span key={'to_admin'}><UserList type={USER_ROLES.ADMIN} /></span>,
        <span key={'to_externalAgents'}><UserList type={USER_ROLES.EXTERNAL_AGENT} /></span>,
        <span key={'to_clients'}><UserList type={USER_ROLES.USER} /></span>,
    ];

    const pageName = "Users";


    return (
        <>
            <AppLayout
                pageName={pageName}
            >
                <div className="main-content-wrapper UserListLayout f-wrp">
                    <div className='panel-main-header f-wrp'>
                        <h1>{'Users'}</h1>
                        <HeaderRightUserInfo />
                    </div>
                    <div className="main-container-wrp">
                        <div className='banner-wrp' style={{display: 'none'}}>
                            <img src={innerBanner} alt='banner' />
                        </div>
                        <Container style={{ paddingTop: '30px' }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className='user-create-btn-wrp'>
                                <Typography variant="h4" gutterBottom>&nbsp;</Typography>
                                <Button onClick={() => navigate('/users/add')} variant="contained" className='blu-btn' startIcon={<Iconify icon="eva:plus-fill" />}>
                                    New User
                                </Button>
                            </Stack>

                            <Card className='table-request' style={{ boxShadow: 'none' }}>
                                <div className='panel-tabel-sec f-wrp'>
                                    <div className="user-main-wrp f-wrp">
                                        <div className="main-container-wrp">
                                            <div className='main-content-box f-wrp'>
                                                <TabLayout
                                                    activeTab={activeTab}
                                                    tabContents={tabContents}
                                                    tabNames={tabNames}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Container>
                    </div>
                </div>
            </AppLayout >
        </>
    )
}
UserListLayout.propTypes = {
    activeTab: PropTypes.any,
}
export default UserListLayout;