import React, { useState, useRef, useEffect } from 'react';

import propTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { translateThis } from '../../helpers/language.helper';


const BasicDropdown = (props) => {
    const {
        label = "select",
        id = "basic-dropdown",
        isDisabled = false,
        isRequired = false,
        value = "",
        options = [],
        onChange
    } = props;
    const [selection, setSelection] = React.useState(value);

    const selectRef = useRef(null);
    const [selectWidth, setSelectWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            if (selectRef.current) {
                setSelectWidth(selectRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    /**
     * Handles the selection
     * @param {Event} event 
     */
    const handleChange = (event) => {
        setSelection(event.target.value);
        onChange(event.target.value)
    };

    return (

        <FormControl fullWidth sx={{ m: 1, minWidth: 200, width: '100%', margin: '0' }} margin='0' disabled={isDisabled}>
            <InputLabel className='selectLabel' id={`label-${id}`} sx={{fontSize: '14px', fontWeight: '500 !important'}}>{translateThis(label)}</InputLabel>
            <Select
                autoWidth
                labelId={`label-${id}`}
                id={`select-${id}`}
                value={selection}
                required={isRequired}
                label={translateThis(label)}
                onChange={handleChange}
                ref={selectRef}
                MenuProps={{
                    PaperProps: {
                        style: {
                            width: selectWidth,
                        },
                    },
                }}
            >
                {[...options].map((option, index) =>
                (<MenuItem style={{fontSize: '14px'}} key={`${index}-${option?.id}-menu`} value={option?.value || option?.id}
                    disabled={(typeof option === 'object') ? (!!option?.disabled) : false}>
                    {translateThis(option.label)}
                </MenuItem>
                ))
                }
            </Select>
        </FormControl>

    );
}
BasicDropdown.propTypes = {
    label: propTypes.string,
    id: propTypes.string,
    value: propTypes.any,
    options: propTypes.array, // array of objects with 'name' and 'id,
    isDisabled: propTypes.bool,
    isRequired: propTypes.bool,
    onChange: propTypes.func,

}
export default BasicDropdown