/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Stack, Button, Grid, InputAdornment, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import { PropertyCategories, PropertyModel } from '../../models/property.model';
import BackArrow from '../../assets/images/BackArrow.svg';
import ArrowsOut from '../../assets/images/ArrowsOut.svg';
import focus from '../../assets/images/focus.svg';

import { showSnackbar } from '../../components/Notification/Snackbar';
import { postProperty } from '../../services/api/property';
import RichTextEditor from '../../components/rich-text-editor';
import AwsS3ImageUploader from '../../components/AwsS3ImageUploader';
import BasicDropdown from '../../components/dropdowns/basic.dropdown';
import SVGIcons from '../../assets/images/icons/svgIcons';
import MapSection from '../../components/MapSection';
import AppLayout from '../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';



// ----------------------------------------------------------------------

export default function AddListItem() {
  const navigator = useNavigate();
  const [propertyItem, setPropertyItem] = useState(PropertyModel);
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyAmenities, setPropertyAmenities] = useState({});
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    location: ''
  });

  // console.log(propertyAmenities, 'propertyAmenities');

  /**
  Sets the value of the property item
  */
  const setValue = (name, value) => {
    const newProp = { ...propertyItem };
    newProp[name] = value;
    setPropertyItem({ ...newProp });
  }

  /**
  Sets the value of the property item
  */
  const setAmenities = (name, value) => {
    const newProp = { ...propertyAmenities };
    newProp[name] = value;
    setPropertyAmenities({ ...newProp });
  }


  /**
   * Handles the form submit
   */
  const handleSubmit = () => {
    if (propertyImages.length > 0 && propertyItem.title && propertyItem.description && propertyItem.category) {
      const { area, address, price } = propertyItem;
      if (!area || !address || !price || !propertyAmenities.length) {
        showSnackbar().warning(`Saving without following fields: ${['Area', 'Address', 'Price', 'Amenities'].filter((_, index) => ![area, address, price, propertyAmenities.length][index]).join(", ")}`);


      }
      const postData = {
        title: propertyItem.title,
        subTitle: propertyItem.subTitle,
        isDiscountedPrice: propertyItem.isDiscountedPrice,
        isNewListing: propertyItem.isNewListing,
        price: propertyItem.price,
        // plot: propertyItem.plot,
        description: propertyItem.description,
        location: location.location,
        latitude: location.latitude,
        longitude: location.longitude,
        images: [
          ...propertyImages.map(img => img.fileKey)
        ],
        thumbnails: [],
        features: propertyItem.features,
        bua: propertyItem.bua,
        agentName: propertyItem.agentName,
        referent: propertyItem.agentName,
        operationType: propertyItem.operationType,
        completionStatus: propertyItem.completionStatus,
        areaMeasureUnit: propertyItem.areaMeasureUnit,
        area: propertyItem.area,
        noOfBedrooms: propertyItem.noOfBedrooms,
        noOfBathrooms: propertyItem.noOfBathrooms,
        category: propertyItem.category,
        subcategory: propertyItem.subcategory,
        metaTags: propertyItem.metaTags,
        metaDescription: propertyItem.metaDescription,
        amenities: propertyAmenities,

      }
      postProperty(postData).then((data) => {
        if (data.success) {
          showSnackbar({}, () => navigator('/dashboard/app')).success('Property added successfully')
        } else {
          showSnackbar().failure(data.message);
        }
      })

    } else {
      showSnackbar().warning('Title, description, images and property type are required');
    }
  }

  const metaTagLength = 60;
  const metaDescriptionLength = 160;

  const metaTagCharacterCount = propertyItem.metaTags.length;
  const metaDescriptionCharacterCount = propertyItem.metaDescription.length;
  const metaTagLimitReached = metaTagCharacterCount >= metaTagLength;
  const metaDescriptionLimitReached = metaDescriptionCharacterCount >= metaDescriptionLength;

  const pageName = "Listing";

  return (
    <>

      <AppLayout
        pageName={pageName}
      >
        <div className="main-content-wrapper f-wrp">
          <div className='panel-main-header f-wrp'>
            <h1>{'All Listings'}</h1>
            <HeaderRightUserInfo />
          </div>
          <div className="main-container-wrp">


            <Container style={{ paddingTop: '30px' }}>

              <div className='list-main-wrp f-wrp' style={{ paddingTop: '10px' }}>
                <div className="breadcrumb f-wrp">
                  <Link to={"/dashboard/app"}><img src={BackArrow} alt="back-arrow" />back</Link>
                </div>
                <div className='title-head f-wrp'>
                  <div className='title-sec'>
                    <Typography variant="h4" gutterBottom>New listing</Typography>
                  </div>

                </div>

                <>
                  <h3>Images*</h3>
                  <AwsS3ImageUploader
                    key={'property-images'}
                    uploaderKey={'property-images'}
                    onUploadEnd={(images) => setPropertyImages(images)}
                    onRemoveFile={(images) => setPropertyImages(images)}
                  />
                </>
                <div className='info-sec-wrp f-wrp'>
                  <div className='general-info-sec f-wrp'>
                    <div className='info-head f-wrp'>
                      <span>General info:</span>
                      {/* <Button className='edit-btn'><img src={edit} alt="edit" /></Button> */}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField type='text'
                            defaultValue="&nbsp;"
                            name="title"
                            label="Title:"
                            required
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                          />
                        </Stack>
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={6}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            id="Subtitle"
                            label="Subtitle:"
                            name='subTitle'
                            defaultValue="&nbsp;"
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                          />
                        </Stack>
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={6}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            id="price"
                            label="price"
                            name='price'
                            required
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  AED
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <BasicDropdown
                            key={'areaUnit'}
                            label="Area unit"
                            id='areaUnit'
                            options={[
                              {
                                'label': 'sqm',
                                "id": 'sqm',
                                "value": 'sqm',
                              },
                              {
                                'label': 'sqf',
                                "id": 'sqf',
                                "value": 'sqf',
                              },

                            ]}
                            onChange={(value) => setValue('areaMeasureUnit', value)}
                            value={propertyItem?.areaMeasureUnit}
                          />
                        </Stack>
                      </Grid>

                      {(propertyItem?.areaMeasureUnit !== '') ? <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            id="AreaInSqmOrSqf"
                            label={`Area: (in ${propertyItem?.areaMeasureUnit})`}
                            name='area'
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {propertyItem?.areaMeasureUnit}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Grid> : null}




                      {/* <Grid item xs={12} sm={4} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            id="Plotsft"
                            label="Plot sft:"
                            name='plot'
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={ArrowsOut} alt="back-arrow" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Grid> */}
                      <Grid item xs={12} sm={4} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            id="BUAsft"
                            label="BUA sft:"
                            name='bua'
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={focus} alt="back-arrow" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Grid>



                      <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <BasicDropdown
                            key={'operationType'}
                            label="Choose operation"
                            id='selectedOperation'
                            options={[
                              {
                                'label': 'Sell',
                                "id": 'sell',
                                "value": 'sell',
                              },
                              {
                                'label': 'Rent',
                                "id": 'rent',
                                "value": 'rent',
                              },
                              {
                                'label': 'Other',
                                "id": 'other',
                                "value": 'other',
                              },

                            ]}
                            onChange={(value) => setValue('operationType', value)}
                            value={propertyItem?.operationType}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <BasicDropdown
                            key={'completionStatus'}
                            label="Completion status"
                            id='completionStatus'
                            options={[
                              {
                                'label': 'Completed',
                                "id": 'completed',
                                "value": 'completed',
                              },
                              {
                                'label': 'In progress',
                                "id": 'inprogress',
                                "value": 'inprogress',
                              },

                            ]}
                            onChange={(value) => setValue('completionStatus', value)}
                            value={propertyItem?.completionStatus}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <BasicDropdown
                            key={'category'}
                            label="Property Type *"
                            id='propertyType'
                            isRequired
                            options={PropertyCategories}
                            onChange={(value) => setValue('category', value)}
                            value={propertyItem?.category}
                          />
                        </Stack>
                      </Grid>
                      {propertyItem?.category !== '' ? <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <BasicDropdown
                            key={'subcategory'}
                            label="Property sub type"
                            id='propertyType'
                            options={PropertyCategories.find((c) => c.id === propertyItem.category)?.subcategories}
                            onChange={(value) => setValue('subcategory', value)}
                            value={propertyItem?.subcategory}
                          />
                        </Stack>
                      </Grid> : null}

                      {/* Area unit actual position */}

                      <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            id="noOfBedrooms"
                            label="Number Of Bedrooms"
                            name='noOfBedrooms'
                            type='number'
                            min='0'
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SVGIcons.BedIcon style={{ width: '18px', height: '18px' }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            id="noOfBathrooms"
                            label="Number Of Bathrooms"
                            name='noOfBathrooms'
                            type='number'
                            min='0'
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SVGIcons.BathIcon style={{ width: '18px', height: '18px' }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            focused
                            type='text'
                            name="agentName"
                            title="Agent name"
                            label="Agent name:"
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            defaultValue="&nbsp;" />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setValue('isDiscountedPrice', e.target.checked)} defaultChecked={propertyItem?.isDiscountedPrice} />} label="Distress Property" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setValue('isNewListing', e.target.checked)} defaultChecked={propertyItem?.isNewListing} />} label="Exclusive" />
                          </FormGroup>
                        </Stack>
                      </Grid>



                      <Grid style={{ display: 'none' }} item xs={12} sm={6} md={6}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField
                            type='text'
                            name="Other"
                            label="Other:"
                            defaultValue="&nbsp;" />
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                  <div className='general-info-sec f-wrp'>
                    <div className='info-head f-wrp'>
                      <span>Description: *</span>
                      {/* <Button className='edit-btn'><img src={edit} alt="edit" /></Button> */}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <RichTextEditor onChange={(value) => setValue('description', JSON.stringify(value))} />
                          {/* <TextField
                      id="Description"
                      label="Description:"
                      name='description'
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                      multiline
                      rows={4}
                      defaultValue="&nbsp;"
                    /> */}
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                  <div className='general-info-sec f-wrp'>
                    <div className='info-head f-wrp'>
                      <span>Features:</span>
                      {/* <Button className='edit-btn'><img src={edit} alt="edit" /></Button> */}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <RichTextEditor onChange={(value) => setValue('features', JSON.stringify(value))} />
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>

                  <div className='general-info-sec f-wrp'>
                    <div className='info-head f-wrp'>
                      <span>Amenities:</span>
                      {/* <Button className='edit-btn'><img src={edit} alt="edit" /></Button> */}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Recreation and family</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('barbequeArea', e.target.checked)} defaultChecked={propertyItem?.amenities?.barbequeArea} />} label="Barbeque area" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('lawnOrGarden', e.target.checked)} defaultChecked={propertyItem?.amenities?.lawnOrGarden} />} label="Lawn or garden" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('cafeteria', e.target.checked)} defaultChecked={propertyItem?.amenities?.cafeteria} />} label="Cafeteria" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('daycare', e.target.checked)} defaultChecked={propertyItem?.amenities?.daycare} />} label="Daycare" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('kidsPlayArea', e.target.checked)} defaultChecked={propertyItem?.amenities?.kidsPlayArea} />} label="Kids play area" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('sundeck', e.target.checked)} defaultChecked={propertyItem?.amenities?.sundeck} />} label="Sundeck" />
                          </FormGroup>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Laundry and kitchen</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('laundryRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.laundryRoom} />} label="Laundry room" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('laundryFacility', e.target.checked)} defaultChecked={propertyItem?.amenities?.laundryFacility} />} label="Laundry facility" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('sharedKitchen', e.target.checked)} defaultChecked={propertyItem?.amenities?.sharedKitchen} />} label="Shared kitchen" />

                          </FormGroup>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Facilities</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('medicalCenter', e.target.checked)} defaultChecked={propertyItem?.amenities?.medicalCenter} />} label="Medical center" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('sauna', e.target.checked)} defaultChecked={propertyItem?.amenities?.sauna} />} label="Sauna" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('jacuzzi', e.target.checked)} defaultChecked={propertyItem?.amenities?.jacuzzi} />} label="Jacuzzi" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('disabledFriendly', e.target.checked)} defaultChecked={propertyItem?.amenities?.disabledFriendly} />} label="Disabled friendly" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('gym', e.target.checked)} defaultChecked={propertyItem?.amenities?.gym} />} label="Gym" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('healthClub', e.target.checked)} defaultChecked={propertyItem?.amenities?.healthClub} />} label="Health club" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('swimmingPool', e.target.checked)} defaultChecked={propertyItem?.amenities?.swimmingPool} />} label="Swimming pool" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('steamRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.steamRoom} />} label="Steam room" />

                          </FormGroup>
                        </Stack>
                      </Grid>


                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Building</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('balcony', e.target.checked)} defaultChecked={propertyItem?.amenities?.balcony} />} label="Balcony" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('terrace', e.target.checked)} defaultChecked={propertyItem?.amenities?.terrace} />} label="Terrace" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('lobby', e.target.checked)} defaultChecked={propertyItem?.amenities?.lobby} />} label="Lobby" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('serviceElevators', e.target.checked)} defaultChecked={propertyItem?.amenities?.serviceElevators} />} label="Service elevators" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('prayerRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.prayerRoom} />} label="Prayer room" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('reception', e.target.checked)} defaultChecked={propertyItem?.amenities?.reception} />} label="Reception" />
                          </FormGroup>
                        </Stack>
                      </Grid>


                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Miscellaneous</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('atmFacility', e.target.checked)} defaultChecked={propertyItem?.amenities?.atmFacility} />} label="ATM facility" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('concierge24h', e.target.checked)} defaultChecked={propertyItem?.amenities?.concierge24h} />} label="24h concierge" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('maidsRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.maidsRoom} />} label="Maids room" />

                          </FormGroup>
                        </Stack>
                      </Grid>


                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Business and security</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('businessCenter', e.target.checked)} defaultChecked={propertyItem?.amenities?.bussinessCenter} />} label="Bussiness center" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('conferenceRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.conferenceRoom} />} label="Conference room" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('securityStaff', e.target.checked)} defaultChecked={propertyItem?.amenities?.securityStaff} />} label="Security staff" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('cctvSecurity', e.target.checked)} defaultChecked={propertyItem?.amenities?.cctvSecurity} />} label="CCTV security" />

                          </FormGroup>
                        </Stack>
                      </Grid>


                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Features</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('doubleGlazedWindows', e.target.checked)} defaultChecked={propertyItem?.amenities?.doubleGlazedWindows} />} label="Double glazed windows" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('centrallyAirConditioned', e.target.checked)} defaultChecked={propertyItem?.amenities?.centralyAirConditioned} />} label="Centraly air-conditioned " />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('centalHeating', e.target.checked)} defaultChecked={propertyItem?.amenities?.centalHeating} />} label="Cental heating" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('electricityBackup', e.target.checked)} defaultChecked={propertyItem?.amenities?.electricityBackup} />} label="Electricity backup" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('studyRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.studyRoom} />} label="Study room" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('storageAreas', e.target.checked)} defaultChecked={propertyItem?.amenities?.storageAreas} />} label="Storage areas" />

                          </FormGroup>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Technology</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('broadbandInternet', e.target.checked)} defaultChecked={propertyItem?.amenities?.broadbandInternet} />} label="Broadband internet" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('satelliteCableTV', e.target.checked)} defaultChecked={propertyItem?.amenities?.sateliteCableTV} />} label="Satelite/Cable TV" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('intercom', e.target.checked)} defaultChecked={propertyItem?.amenities?.intercom} />} label="Intercom" />

                          </FormGroup>
                        </Stack>
                      </Grid>


                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                          <div className='info-head f-wrp'><span>Cleaning and maintenance</span></div>
                          <FormGroup style={{ flexDirection: 'row' }}>
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('cleaningServices', e.target.checked)} defaultChecked={propertyItem?.amenities?.cleaningservices} />} label="Cleaning services" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('maintenanceStaff', e.target.checked)} defaultChecked={propertyItem?.amenities?.maintenanceStaff} />} label="Maintenance staff" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('wasteDisposal', e.target.checked)} defaultChecked={propertyItem?.amenities?.wasteDisposal} />} label="Waste disposal" />

                          </FormGroup>
                        </Stack>
                      </Grid>

                    </Grid>
                  </div>

                  <div className='general-info-sec f-wrp'>
                    <div className='info-head f-wrp'>
                      <span>Location</span>
                      {/* <Button className='edit-btn'><img src={edit} alt="edit" /></Button> */}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <MapSection handleSelection={(location) => setLocation(location)} />
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>

                  <div className='general-info-sec f-wrp'>
                    <div className='info-head f-wrp'>
                      <span>Meta details</span>
                    </div>
                    <Grid container spacing={3}>

                      <Grid item xs={12} sm={6} md={6}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField type='text'
                            defaultValue="&nbsp;"
                            name="metaTags"
                            label="Meta Tags"
                            helperText={metaTagLimitReached ? 'You exceeded the recommended 60 amount of characters for optimal results' : ""}
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                            maxLen
                          />
                        </Stack>
                        <p style={{marginBottom: '0', marginTop: '5px', fontSize: '12px', color: '#777'}}><b>Meta Tags: </b>Enter the title for the meta, such as the property name.</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Stack className='login-form profile-form' spacing={3}>
                          <TextField type='text'
                            defaultValue="&nbsp;"
                            name="metaDescription"
                            label="Meta Description"
                            helperText={metaDescriptionLimitReached ? 'You exceeded the recommended 160 amount of characters for optimal results' : ""}
                            onChange={({ target: { name, value } }) => setValue(name, value)}
                          />
                        </Stack>
                        <p style={{marginBottom: '0', marginTop: '5px', fontSize: '12px', color: '#777'}}><b>Meta Description: </b>Add relevant descriptions, such as property details and SEO-related terms.</p>
                      </Grid>
                    </Grid>
                  </div>


                </div>
                <div className='button-sec f-wrp'>
                  <Button onClick={() => navigator('/dashboard/app')} className='cancel'>Cancel</Button> &nbsp;
                  <Button onClick={handleSubmit} className='fill-btn'>Save changes</Button>
                </div>
              </div>
            </Container>


          </div>
        </div>
      </AppLayout >
    </>
  );
}
