import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { TextField } from '@mui/material';

const GOOGLE_MAPS_API_KEY = 'AIzaSyC8EoTsRiBJeLu0kC1fMibKYTHKDVKfbl0'; // Replace with your API key

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 0.00,
  lng: 0.00
};

// Define libraries outside the component to avoid re-creation on each render
const libraries = ['places'];

const MapComponent = React.memo((props) => {
  const {
    handleSelection = () => null,
    initialData = {
      latitude: 0.0,
      longitude: 0.0,
      location: ''
    },
    isViewOnly = false
  } = props;

  // eslint-disable-next-line
  const [map, setMap] = useState(null);

  const [searchBox, setSearchBox] = useState(null);
  const [placeName, setPlaceName] = useState(initialData?.location);
  const [latitude, setLatitude] = useState(initialData?.latitude);
  const [longitude, setLongitude] = useState(initialData?.longitude);

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const onSearchBoxLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const placeName = place.formatted_address;
      onGettingLatAndLng(lat, lng, placeName);
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    onGettingLatAndLng(lat, lng, placeName);
  };

  /**
   * Handles the latitude and longitude
   * @param {string} placeName 
   * @param {string} lat 
   * @param {string} lng 
   */
  const onGettingLatAndLng = (lat, lng, placeName) => {
    setLatitude(lat);
    setLongitude(lng);
    setPlaceName(placeName);
    if (typeof handleSelection === 'function') {
      handleSelection({
        latitude: lat,
        longitude: lng,
        location: placeName
      })
    }
    console.log(`Latitude: ${lat}, Longitude: ${lng}`);
  }

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <div style={{ position: 'relative', width: '100%' }}>
        {!isViewOnly ? <> <StandaloneSearchBox
          onLoad={onSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}
          style={{
            width: '100%',
          }}
        >
          <TextField type='text'
            name="location"
            defaultValue={placeName}
            label="Area:"
            required
            placeholder='Location...'
            style={{
              width: '100%',
            }}
          />

        </StandaloneSearchBox>
          <br />
          <br /> </> : null}
        {latitude !== null && longitude !== null ? <GoogleMap
          mapContainerStyle={containerStyle}
          center={latitude && longitude ? { lat: latitude, lng: longitude } : center}
          zoom={10}
          options={{
            disableDefaultUI: true
          }}
          onLoad={onLoad}
          onClick={isViewOnly ? undefined : handleMapClick} // Add onClick event handler
        >
          {latitude && longitude && (
            <Marker position={{ lat: latitude, lng: longitude }} />
          )}
        </GoogleMap> : null}
      </div>
    </LoadScript>
  );
});

MapComponent.propTypes = {
  handleSelection: PropTypes.func,
  initialData: PropTypes.shape({
    location: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  isViewOnly: PropTypes.bool
}
export default MapComponent;